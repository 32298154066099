import React, { useEffect, useState } from 'react';
import axios from 'axios'; // For making API requests
import { useParams, useNavigate } from 'react-router-dom'; // For getting the URL parameters
import './QRCodePage.css'; // Import your custom styles
import LoadingSpinner from './LoadingSpinner';

const QRCodePage = () => {
  const { name, amount, userId } = useParams();
  const [qrData, setQrData] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [orderId, setOrderId] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // For navigation

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const POLLING_INTERVAL_MS = 5000; // 5 seconds
  

  // Helper function to calculate expiry time (5 minutes from now)
  const calculateExpiryTime = () => {
    const currentTime = new Date();
    const expiryTime = new Date(currentTime.getTime() + 5 * 60000); // Add 5 minutes
    return expiryTime.toISOString(); // Return ISO format for consistency
  };
  // Fetch data from API using the `name` and `amount` from URL
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/payment/${name}/${amount}?userId=${userId}`);
        const result = response.data.result;
        setQrData(result.qrImage);
        setTotalAmount(result.totalAmount);
        setExpiryTime(calculateExpiryTime());
        setOrderId(result.referenceNo);
        setReferenceId(result.referenceNo);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };
    fetchData();
  }, [name, amount]); // Run the effect whenever `name` or `amount` changes

  useEffect(() => {
    let pollingInterval;
    if (referenceId) {
      pollingInterval = setInterval(async () => {
        try {
          const response = await axios.get(`${apiBaseUrl}/status/${referenceId}`);
          const status = response.data.result.status;
          if (status === 'DONE') {
            // Navigate to success page once payment is completed
            navigate('/success', { state: { totalAmount, orderId, name } });
            clearInterval(pollingInterval); // Stop polling
          }
        } catch (err) {
          console.error('Failed to check status');
          setError('Failed to check status');
        }
      }, POLLING_INTERVAL_MS);
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(pollingInterval);
  }, [referenceId, totalAmount, orderId, apiBaseUrl, navigate]);

  // Function to handle the check status button click
  const checkStatus = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/status/${referenceId}`);
      const status = response.data.result.status;
      if (status === 'DONE') {
        // Navigate to a success page (or display a success message)
        navigate('/success', { state: { totalAmount, orderId, name } });
      } else {
        alert('Payment not yet completed.');
      }
    } catch (err) {
      setError('Failed to check status');
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="qr-page-container">
      <div className="qr-card">
        <div className="qr-header">
          <h1>{name}</h1> {/* Use the `name` from the URL as the title */}
        </div>
        <div className="qr-content">
          <h2>Rp{totalAmount}</h2>
          <p>Order ID #{orderId}</p>
          <p>User ID: {userId}</p> {/* Display the user ID */}
          <div className="pay-timer">
            <span>Pay before: {new Date(expiryTime).toLocaleString()}</span>
          </div>
          <div className="qr-section">
            <h3>QRIS</h3>
            {qrData && <img src={qrData} alt="QR Code" />}
          </div>
          <div className="actions">
            <button className="btn-download">Download QRIS</button>
            <button className="btn-status" onClick={checkStatus}>Check status</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodePage;
